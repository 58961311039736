import github from '../images/github.svg'
import internet from '../images/internet.svg'

const ProjectLinks = ({links}) => {
  console.log(links)
  return (
    <div className="project-links-cont">
      <div className="project-links-title-cont">
        <h2>Project Links</h2>
      </div>
      <div className="project-link-hyperlinks-cont-outer">
        <div className="project-link-cont">
          <div className="project-logo-cont">
            <img src={internet} alt="Internet" />
          </div>
          <div className="project-hyperlink-cont">
            <a href={links?.hyperlinks.internet} target='_blank'>Click here to go to site.</a>
          </div>
        </div>
        <div className="project-link-cont">
          <div className="project-logo-cont">
            <img src={github} alt="GitHub" />
          </div>
          <div className="project-hyperlink-cont">
            <a href={links?.hyperlinks.github} target='_blank'>Click here to go to GitHub.</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectLinks