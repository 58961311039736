import { Link } from "react-router-dom"
import Header from "../components/Header"


const Home = () => {

  return (
    <div className="home-cont-outer">

      <Header/>
      
      <div className="home-cont-inner">

        <h1></h1>

      </div>

    </div>
  )
}

export default Home