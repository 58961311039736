


const ContentLinks = ({contentLinks}) => {

  console.log(contentLinks)
  return (
    <div className="content-links-outer">
        <div className="content-links-title">
            <h2>Contents</h2>
        </div>
        <div className="content-links-inner">
          {contentLinks?.map(individualContentLink => (
              <div className="content-links-container">
                <div className="container-links-mainLink">
                  <a href={individualContentLink.mainLink.hyperlink}>{individualContentLink.mainLink.title}</a>
                </div>
                {individualContentLink.subLinks ? (
                  individualContentLink.subLinks.map(individualSubLink => (
                    <div className="container-links-subLink">
                      <a href={individualSubLink.hyperlink}>{individualSubLink.title}</a>
                    </div>
                  ))
                ) : ""}
                {/* <div className="container-links-subLink">
                  <a href="#chapter2">Chapter 2</a>
                </div>
                <div className="container-links-subLink">
                  <a href="#chapter2">Chapter LOL</a>
                </div> */}
            </div>
          ))}
            {/* <div className="content-links-container">
              <div className="container-links-mainLink">
                <a href="#chapter1">Chapter 1</a>
              </div>
              <div className="container-links-subLink">
                <a href="#chapter2">Chapter 2</a>
              </div>
              <div className="container-links-subLink">
                <a href="#chapter2">Chapter LOL</a>
              </div>
            </div> */}
        </div>
    </div>
  )
}

export default ContentLinks