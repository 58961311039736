
const Title = ({props}) => {

  // console.log("--------------------------------")
  // console.log(props)



  return (
    <div className="chapter-cont-inner-title" id={props.id ? props.id : ""}>
        <h1>{props.text}</h1>
        {/* <h1>Human Resources Information System (HRIS)</h1> */}
    </div>
  )
}

export default Title