
const Ul = ({props}) => {
  return (
    <div className="chapters-cont-inner-ul" id={props.id ? props.id : ""}>
        <div className="ul-title">
            <p>{props.title}</p>
            {/* <p>Just a generic UL title:</p> */}
        </div>
        <div className="ul-ul">
            <ul>
              {props.listItems.map(listItem => (
                <li>{listItem}</li>
              ))}

                {/* <li>a</li> */}
            </ul>
        </div>
    </div>
  )
}

export default Ul