
const Table = ({props}) => {
  return (
    <div className='chapter-cont-inner-table' id={props.id ? props.id : ""}>
      <div className="table-caption">
        <p>{props.caption}</p>
        {/* <p>Just a generic table caption.</p> */}
      </div>
      <div className="table-tbl">
        <table>

          <tr>
            {props.tbl_headings.map(heading => (
              <th>{heading}</th>
            ))}
          </tr>

          {props.tbl_data.map(data => (
            <tr>
              {data.map(dataV2 => (
                <td>{dataV2}</td>
              ))}
            </tr>
          ))}

          {/* <tr>
            <th>Company</th>
            <th>Contact</th>
            <th>Country</th>
          </tr>
          <tr>
            <td>Alfreds Futterkiste</td>
            <td>Maria Anders</td>
            <td>Germany</td>
          </tr>
          <tr>
            <td>Centro comercial Moctezuma</td>
            <td>Francisco Chang</td>
            <td>Mexico</td>
          </tr>
          <tr>
            <td>
              - LOL
              -dlak
            </td>
            <td>Roland Mendel</td>
            <td>
              - jodn <br/>
              - skajn
            </td>
          </tr>
          <tr>
            <td>Island Trading</td>
            <td>Helen Bennett</td>
            <td>UK</td>
          </tr>
          <tr>
            <td>Laughing Bacchus Winecellars</td>
            <td>Yoshi Tannamuri</td>
            <td>Canada</td>
          </tr>
          <tr>
            <td>Magazzini Alimentari Riuniti</td>
            <td>Giovanni Rovelli</td>
            <td>Italy</td>
          </tr> */}
        </table>
      </div>
    </div>
  )
}

export default Table