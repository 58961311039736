

const Image = ({props}) => {
  return (
    <div className="chapter-cont-inner-imgCont" id={props.id ? props.id : ""}>
        <div className="imgCont-imgContainer">
            <img src={props.image} alt={props.altText} />
            {/* <img src={sunset} alt="Sunset" /> */}
        </div>
        <div className="imgCont-imgCaption">
            <p>{props.caption}</p>
            {/* <p>Just a generic image caption.</p> */}
        </div>
    </div>
  )
}

export default Image