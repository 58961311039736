
const A = ({props}) => {
  return (
    <div className="chapters-cont-inner-a" id={props.id ? props.id : ""}>
        <a href={props.hyperlink} target="_blank">{props.text}</a>
        {/* <a href="https://google.com" target="_blank">Just a generic anchor tag.</a> */}
    </div>
  )
}

export default A