import { useEffect, useState } from "react"

import Info from "./contents/Info"
import Title from "./contents/Title"
import H2 from "./contents/H2"
import H3 from "./contents/H3"
import H4 from "./contents/H4"
import P from "./contents/P"
import A from "./contents/A"
import Ul from "./contents/Ul"
import Ol from "./contents/Ol"
import Image from "./contents/Image"
import Table from "./contents/Table"

const Chapter = ({chapter}) => {
  const [content,setContent] = useState([])

  const getComponent = (tag) => {

    if(tag.substring(0,4)=="info"){
      // console.log('made it')
      setContent((prev)=>([...prev, <Info props={chapter[tag]}/>]))
    }

    if(tag.substring(0,5)=="title"){
      // console.log('made it')
      setContent((prev)=>([...prev, <Title props={chapter[tag]}/>]))
    }

    if(tag.substring(0,2)=="h2"){
      // console.log('made it')
      setContent((prev)=>([...prev, <H2 props={chapter[tag]}/>]))
    }

    if(tag.substring(0,2)=="h3"){
      // console.log('made it')
      setContent((prev)=>([...prev, <H3 props={chapter[tag]}/>]))
    }

    if(tag.substring(0,2)=="h4"){
      // console.log('made it')
      setContent((prev)=>([...prev, <H4 props={chapter[tag]}/>]))
    }

    if(tag.substring(0,1)=="p"){
      // console.log('made it')
      setContent((prev)=>([...prev, <P props={chapter[tag]}/>]))
    }

    if(tag.substring(0,1)=="a"){
      // console.log('made it')
      setContent((prev)=>([...prev, <A props={chapter[tag]}/>]))
    }

    if(tag.substring(0,2)=="ul"){
      // console.log('made it')
      setContent((prev)=>([...prev, <Ul props={chapter[tag]}/>]))
    }

    if(tag.substring(0,2)=="ol"){
      // console.log('made it')
      setContent((prev)=>([...prev, <Ol props={chapter[tag]}/>]))
    }

    if(tag.substring(0,5)=="image"){
      // console.log('made it')
      setContent((prev)=>([...prev, <Image props={chapter[tag]}/>]))
    }

    if(tag.substring(0,5)=="table"){
      // console.log('made it')
      setContent((prev)=>([...prev, <Table props={chapter[tag]}/>]))
    }

    if(tag.substring(0,3)=="gif"){
      // console.log('made it')
      setContent((prev)=>([...prev, <Image props={chapter[tag]}/>]))
    }
 


    
  }

  const parseTags = (chapter) => {

    for (const tag in chapter) {
      // console.log(tag)
      // console.log(tag.substring(0,5))
      // console.log(typeof tag) 
      // console.log(chapter[tag]) 
      // console.log(chapter.tag) 
      getComponent(tag)
      // setContent((prev)=>([...prev, getComponent(tag)]))
      // content.push(getComponent(tag))
    }

  }
  
  // console.log(chapter)

  useEffect(()=>{
    parseTags(chapter)
  },[])


  return (
    <div className="chapter-cont-outer" id={chapter.chapterId ? chapter.chapterId : "" }>
    {/* <Info/> */}
    {/* <Title/> */}
    {/* <H2/> */}
    {/* <H3/> */}
    {/* <P/> */}
    {/* <Image/> */}
    {/* <Table/> */}
    {/* <Ul/> */}
    {/* <Ol/> */}
    {/* <H4/> */}
    {/* <A/> */}

    {content}

    {/* <Title/>
    <Info/>
    <H2/>
    <P/>
    <A/>
    <H4/>
    <Ul/>
    <H3/>
    <H2/>
    <H4/>
    <P/>
    <P/>
    <Table/>
    <H3/>
    <P/>
    <H2/>
    <Ol/>
    <Image/>
    <H3/>
    <P/>
    <P/> */}

    </div>
  )
}

export default Chapter