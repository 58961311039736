import { ticTacToe } from "./projects/ticTacToe/ticTacToe.js";
import {calculator} from "./projects/calculator/calculator.js"
import {streamflix} from "./projects/streamflix/streamflix.js"

export const getIndividualProject = ({params}) => {
    const {projectName} = params

    switch(projectName){
        case 'ticTacToe':
            return ticTacToe;
        case 'calculator':
            return calculator;
        case 'streamFlix':
            return streamflix;
        default:
            return null;
    }
        
}