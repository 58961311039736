import { useState, useEffect } from "react"
import Header from "../components/Header"
import { useLoaderData } from "react-router-dom"
import Card from "../components/Card"

import {motion, AnimatePresence} from 'framer-motion'

import typescript from '../images/typescript.svg'
import javascript from '../images/javascript.svg'
import go from '../images/go.svg'
import java from '../images/java.svg'
import python from '../images/python.svg'
import cpp from '../images/cpp.svg'

import react from '../images/react.svg'
import spring from '../images/spring.svg'
import nodejs from '../images/nodejs.svg'
import fiber from '../images/fiber.svg'
import raylib from '../images/raylib.png'



const Projects = () => {
    const [projects, setProjects] = useState([])
    const [filtered, setFiltered] = useState([])
    const [activeFilter, setActiveFilter] = useState("")
    const [projectsCount, setProjectsCount] = useState(0)

    const receivedFromLoader = useLoaderData()

    const resetFilter = () => {
        setFiltered(projects)
        setProjectsCount(projects.length)
        setActiveFilter("")
    }

    const filterProjectsByLanguage = (language) => {
        // console.log('in the filter func')
        switch(language) {
            case 'typescript':
                var newArr = projects.filter(proj=>proj.frontCard.languages.includes('typescript'))
                setFiltered(newArr)
                setProjectsCount(newArr.length)
                setActiveFilter("typescript")
                break;
            case 'javascript':
                var newArr = projects.filter(proj=>proj.frontCard.languages.includes('javascript'))
                setFiltered(newArr)
                setProjectsCount(newArr.length)
                setActiveFilter("javascript")
                break;
            case 'go':
                var newArr = projects.filter(proj=>proj.frontCard.languages.includes('go'))
                setFiltered(newArr)
                setProjectsCount(newArr.length)
                setActiveFilter("go")
                break;
            case 'java':
                var newArr = projects.filter(proj=>proj.frontCard.languages.includes('java'))
                setFiltered(newArr)
                setProjectsCount(newArr.length)
                setActiveFilter("java")
                break;
            case 'python':
                var newArr = projects.filter(proj=>proj.frontCard.languages.includes('python'))
                setFiltered(newArr)
                setProjectsCount(newArr.length)
                setActiveFilter("python")
                break;
            case 'cpp':
                var newArr = projects.filter(proj=>proj.frontCard.languages.includes('cpp'))
                setFiltered(newArr)
                setProjectsCount(newArr.length)
                setActiveFilter("cpp")
                break;
            default:
                break;
        }

    }

    const filterProjectsByFramework = (framework) => {
        switch(framework) {
            case 'react':
                var newArr = projects.filter(proj=>proj.frontCard.frameworks.includes('react'))
                setFiltered(newArr)
                setProjectsCount(newArr.length)
                setActiveFilter("react")
                break;
            case 'fiber':
                var newArr = projects.filter(proj=>proj.frontCard.frameworks.includes('fiber'))
                setFiltered(newArr)
                setProjectsCount(newArr.length)
                setActiveFilter("fiber")
                break;
            case 'nodejs':
                var newArr = projects.filter(proj=>proj.frontCard.frameworks.includes('nodejs'))
                setFiltered(newArr)
                setProjectsCount(newArr.length)
                setActiveFilter("nodejs")
                break;
            case 'springboot':
                var newArr = projects.filter(proj=>proj.frontCard.frameworks.includes('springboot'))
                setFiltered(newArr)
                setProjectsCount(newArr.length)
                setActiveFilter("springboot")
                break;
            case 'raylib':
                var newArr = projects.filter(proj=>proj.frontCard.frameworks.includes('raylib'))
                setFiltered(newArr)
                setProjectsCount(newArr.length)
                setActiveFilter("raylib")
                break;
            default:
                break;
        }
    }

    const filterProjectsByAppType = (appType) => {
        switch(appType) {
            case 'Front-End':
                var newArr = projects.filter(proj=>proj.appType.includes('Front-End'))
                setFiltered(newArr)
                setProjectsCount(newArr.length)
                setActiveFilter("Front-End")
                break;
            case 'Full-Stack':
                var newArr = projects.filter(proj=>proj.appType.includes('Full-Stack'))
                setFiltered(newArr)
                setProjectsCount(newArr.length)
                setActiveFilter("Full-Stack")
                break;
            case 'Game Development':
                var newArr = projects.filter(proj=>proj.appType.includes('Game Development'))
                setFiltered(newArr)
                setProjectsCount(newArr.length)
                setActiveFilter("Game Development")
                break;
            case 'Machine Learning':
                var newArr = projects.filter(proj=>proj.appType.includes('Machine Learning'))
                setFiltered(newArr)
                setProjectsCount(newArr.length)
                setActiveFilter("Machine Learning")
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        setProjects(receivedFromLoader)
        setFiltered(receivedFromLoader)
        setProjectsCount(receivedFromLoader.length)
    }, [receivedFromLoader])

  return (
    <div className="projects-outer-cont">
        <div className="projects-inner-cont">
            <div className="projects-filters-outer">
                <div className="projects-filters-title">
                    <h2 id="filters" >Filters</h2>
                    <h2 id='reset' onClick={()=>(resetFilter())}>Reset</h2>
                </div>
                <div className="projects-filters-content">
                    <div id="filters-languages">
                        <div className="filter-content">
                            <div className="filter-title">Language</div>
                            <div className="filter-options">
                                <div id="options-language" onClick={()=>{filterProjectsByLanguage('typescript')}} 
                                className={activeFilter == "typescript" ? "activeFilter" : ""}>
                                    <div className="filter-logo">
                                        <img src={typescript} alt="TypeScript" />
                                    </div>
                                    <h2>TypeScript</h2>
                                </div>
                                <div id="options-language" onClick={()=>{filterProjectsByLanguage('javascript')}}
                                    className={activeFilter == "javascript" ? "activeFilter" : ""}>
                                    <div className="filter-logo">
                                        <img src={javascript} alt="JavaScript" />
                                    </div>
                                    <h2>Javascript</h2>
                                </div>
                                <div id="options-language" onClick={()=>{filterProjectsByLanguage('go')}}
                                    className={activeFilter == "go" ? "activeFilter" : ""}>
                                    <div className="filter-logo">
                                        <img src={go} alt="Go" />
                                    </div>
                                    <h2>Go</h2>
                                </div>
                                <div id="options-language" onClick={()=>{filterProjectsByLanguage('java')}}
                                    className={activeFilter == "java" ? "activeFilter" : ""}>
                                    <div className="filter-logo">
                                        <img src={java} alt="Java" />
                                    </div>
                                    <h2>Java</h2>
                                </div>
                                <div id="options-language" onClick={()=>{filterProjectsByLanguage('python')}}
                                    className={activeFilter == "python" ? "activeFilter" : ""}>
                                    <div className="filter-logo">
                                        <img src={python} alt="Python" />
                                    </div>
                                    <h2>Python</h2>
                                </div>
                                <div id="options-language" onClick={()=>{filterProjectsByLanguage('cpp')}}
                                    className={activeFilter == "cpp" ? "activeFilter" : ""}>
                                    <div className="filter-logo">
                                        <img src={cpp} alt="C++" />
                                    </div>
                                    <h2>C++</h2>
                                </div>
                                {/* <h2>TypeScript</h2>
                                <h2>JavaScript</h2>
                                <h2>Go</h2>
                                <h2>Java</h2>
                                <h2>Python</h2>
                                <h2>C++</h2> */}
                            </div>
                        </div>
                    </div>
                    <div id="filters-frameworks">
                        <div className="filter-content">
                            <div className="filter-title">Frameworks & Libraries</div>
                            <div className="filter-options">
                                <div id="options-framework" onClick={()=>{filterProjectsByFramework('react')}}
                                    className={activeFilter == "react" ? "activeFilter" : ""}>
                                    <div className="filter-logo">
                                        <img src={react} alt="React" />
                                    </div>
                                    <h2>React</h2>
                                </div>
                                <div id="options-framework" onClick={()=>{filterProjectsByFramework('fiber')}}
                                    className={activeFilter == "fiber" ? "activeFilter" : ""}>
                                    <div className="filter-logo">
                                        <img src={fiber} alt="Fiber" />
                                    </div>
                                    <h2>Fiber</h2>
                                </div>
                                <div id="options-framework" onClick={()=>{filterProjectsByFramework('nodejs')}}
                                    className={activeFilter == "nodejs" ? "activeFilter" : ""}>
                                    <div className="filter-logo">
                                        <img src={nodejs} alt="Node Js" />
                                    </div>
                                    <h2>Node JS</h2>
                                </div>
                                <div id="options-framework" onClick={()=>{filterProjectsByFramework('springboot')}}
                                    className={activeFilter == "springboot" ? "activeFilter" : ""}>
                                    <div className="filter-logo">
                                        <img src={spring} alt="Spring Boot" />
                                    </div>
                                    <h2>Spring Boot</h2>
                                </div>
                                <div id="options-framework" onClick={()=>{filterProjectsByFramework('raylib')}}
                                    className={activeFilter == "raylib" ? "activeFilter" : ""}>
                                    <div className="filter-logo">
                                        <img src={raylib} alt="Raylib" />
                                    </div>
                                    <h2>Raylib</h2>
                                </div>
                                {/* <h2>React</h2>
                                <h2>Fiber</h2>
                                <h2>Springboot</h2>
                                <h2>Node JS</h2>
                                <h2>Raylib</h2>
                                <h2>TensorFlow</h2> */}
                            </div>
                        </div>
                    </div>
                    <div id="filters-app-type">
                        <div className="filter-content">
                            <div className="filter-title">App Type</div>
                            <div className="filter-options">
                                <div id="options-app-type" onClick={()=>{filterProjectsByAppType('Front-End')}}
                                    className={activeFilter == "Front-End" ? "activeFilter" : ""}>
                                    <h2>Front-End</h2>
                                </div>
                                <div id="options-app-type" onClick={()=>{filterProjectsByAppType('Full-Stack')}}
                                    className={activeFilter == "Full-Stack" ? "activeFilter" : ""}>
                                    {/* <div className="filter-logo">
                                        <img src={typescript} alt="TypeScript" />
                                    </div> */}
                                    <h2>Full-Stack</h2>
                                </div>
                                <div id="options-app-type" onClick={()=>{filterProjectsByAppType('Game Development')}}
                                    className={activeFilter == "Game Development" ? "activeFilter" : ""}>
                                    <h2>Game Development</h2>
                                </div>
                                <div id="options-app-type" onClick={()=>{filterProjectsByAppType('Machine Learning')}}
                                    className={activeFilter == "Machine Learning" ? "activeFilter" : ""}>
                                    <h2>Machine Learning</h2>
                                </div>
                                {/* <h2>Front-End</h2>
                                <h2>Full-Stack</h2>
                                <h2>Game Development</h2>
                                <h2>Machine Learning</h2>
                                <h2>Miscellaneous</h2> */}
                            </div>
                        </div>
                    </div>
                    {/* <div id="filters-cloud">
                         <div className="filter-content">
                            <div className="filter-title">Cloud & Containerization</div>
                            <div className="filter-options">
                                <h2>Docker</h2>
                                <h2>AWS</h2>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="projects-content">
                <div className="projects-metadata">
                    <h2 id="results">Results: {projectsCount}</h2>
                </div>
                <div className="projects-list-outer">
                    <motion.div layout className="projects-list-inner">
                        <AnimatePresence >
                            {filtered.map(project=>(
                                <Card key={project.projectId} proj={project}/>
                            ))}
                        </AnimatePresence>
                    </motion.div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Projects