
import mrbean from '../../../../images/mrbean.gif'


export const calculator = {
    links:{
        hyperlinks:{
            internet:"https://md98-calculator.netlify.app",
            github:"https://github.com/maxamed98/calculator"
        },
    },
    contentLinks:[
        // {
        //     mainLink:{
        //         title:"Main link 1",
        //         hyperlink:"#ml1"
        //     },
        //     subLinks:[
        //         {
        //         title:"Sub link 1 - For main link 1",
        //         hyperlink:"#sl1fml1"
        //         },
        //         {
        //         title:"Sub link 2 - For main link 1",
        //         hyperlink:"#sl2fml1"
        //         },
        //     ]
        // },
        // {
        //     mainLink:{
        //         title:"Main link 2",
        //         hyperlink:"#ml2"
        //     },
        //     subLinks:[
        //         {
        //         title:"Sub link 1 - For main link 2",
        //         hyperlink:"#sl1fml2"
        //         },
        //         {
        //         title:"Sub link 2 - For main link 2",
        //         hyperlink:"#sl2fml2"
        //         },
        //     ]
        // },
       
    ],
    chapters:[
        {
            chapterId:"",
            title_1:{
                id:"",
                text:`Calculator`
            },
            gif_1:{
                id:"",
                caption:`Nothing to see here.`,
                altText:`mr bean.`,
                image:mrbean
            },
        },
        
        
    ]
    
}