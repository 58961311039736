export const allProjectsData = [
    {
        projectId: 1,
        projectName:"streamFlix",
        appType:['Front-End'],
        frontCard:{
            db: '',
            cloud: ['netlify'],
            title:"Film & TV App",
            tags: ['Front-End'],
            languages:['javascript'],
            frameworks:['react']
        },
        backCard:{
            description:'Users can browse content ranging from movies, tv shows & anime.',
            keyFeatures:[
                'Trending Movies & TV shows are shown on the homepage',
                'Users can filter content by genre',
                'Detailed information is given on each piece of content'
            ],
            deployment:[
                'The client tier is deployed on Netlify.'
            ]
        }
    },
    {
        projectId: 2,
        projectName:"calculator",
        appType:['Front-End'],
        frontCard:{
            db: '',
            cloud: ['netlify'],
            title:"Calculator",
            tags: ['Front-End'],
            languages:['javascript'],
            frameworks:['react']
        },
        backCard:{
            description:'Although we may take it for granted, before the calculator people had to use a slide rule!',
            keyFeatures:[
                'Addition',
                'Subtraction',
                'Multiplication',
                'Division',
                'Reset',
                'Delete'
            ],
            deployment:['The client tier is deployed on Netlify.']
        }
    },
    {
        projectId: 3,
        projectName:"ticTacToe",
        appType:['Game Development'],
        frontCard:{
            db: '',
            cloud: ['netlify'],
            title:"Tic Tac Toe",
            tags: ['Game Development'],
            languages:['javascript'],
            frameworks:['react']
        },
        backCard:{
            description:'A classic game.',
            keyFeatures:[
                'Players take turns to fill a square',
                'The first player with 3 contiguous squares (including diagonal) wins',
                'If no player achieves the above, the game ends in a draw'
            ],
            deployment:['The client tier is deployed on Netlify.']
        }
    },
    
    
    

]