import profile from '../images/profile.svg'
import github from '../images/github.svg'
import linkedin from '../images/linkedin.svg'

const MyDetails = () => {
  return (
    <div className="my-details-cont">
        <div className="profile-pic-cont-outer">
            <div className="profile-pic-cont-inner">
                <img src={profile} alt="Profile Photo" />
            </div>
        </div>
        <div className="profile-info-cont-outer">
            <div className="name-cont">
                <h2>Mohamed Dahir</h2>
            </div>
            <div className="personal-links-cont">
                <div className="personal-link-cont">
                    <div className="personal-link-logo-outer-cont">
                        <div className="personal-link-logo-inner-cont">
                            <img src={github} alt="Github" />
                        </div>
                    </div>
                    <div className="personal-link-hyperlink-cont">
                        <a href="https://www.github.com/maxamed98" target='_blank'>github.com/maxamed98</a>
                    </div>
                </div>
                {/* <div className="personal-link-cont">
                    <div className="personal-link-logo-outer-cont">
                        <div className="personal-link-logo-inner-cont">
                            <img src={linkedin} alt="LinkedIn" />
                        </div>
                    </div>
                    <div className="personal-link-hyperlink-cont">
                        <a href="https://www.linkedin.com/in/maxamed98" target='_blank'>linkedin.com/in/maxamed98</a>
                    </div>
                </div> */}
            </div>
        </div>
    </div>
  )
}

export default MyDetails