import info from '../../images/info.svg'

const Info = ({props}) => {
  return (
    <div className="chapter-cont-inner-info" id={props.id ? props.id : ""}>
        <div className="info-inner-cont">
            <div className="info-logo-cont">
                <img src={info} alt="Info" />
            </div>
            <div className="info-text">
                <h2>{props.text}</h2>
                {/* <h2>This is a generic info message.</h2> */}
            </div>
        </div>
    </div>
  )
}

export default Info