
const Ol = ({props}) => {
  return (
    <div className="chapters-cont-inner-ol" id={props.id ? props.id : ""}>
        <div className="ol-title">
          <p>{props.title}</p>
          {/* <p>Just a generic UL title:</p> */}
        </div>
        <div className="ol-ol">
            <ol>
              {props.listItems.map(listItem => (
                  <li>{listItem}</li>
                ))}

                {/* <li>a</li> */}
            </ol>
        </div>
    </div>
  )
}

export default Ol