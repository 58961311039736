import { useState, useEffect } from "react"
import { useLoaderData } from "react-router-dom"

import MyDetails from "../components/MyDetails";
import ProjectLinks from "../components/ProjectLinks";
import ContentLinks from "../components/ContentLinks";
import Chapter from "../components/Chapter";


const ProjectDetails = () => {
  const [project, setProject] = useState({});

  const receivedFromLoader = useLoaderData()

  useEffect(()=>{
    setProject(receivedFromLoader)
    // console.log("project received from loader")
    // console.log(receivedFromLoader)
  }, [receivedFromLoader])


  return (
    <div className="project-details-outer">
      {/* <div className="project-details-inner"> */}
        <div className="metadata-cont">
          <MyDetails/>
          <ProjectLinks links={project?.links}/>
        </div>
        <div className="information-cont">
          {project?.chapters?.map(chapter => (
            <Chapter chapter={chapter}/>
          ))}
         
        </div>
        <div className="content-links-cont">
          <ContentLinks contentLinks={project?.contentLinks}/>
        </div>
      {/* </div> */}
    </div>
  )
}

export default ProjectDetails