
const H3 = ({props}) => {
  return (
    <div className="chapter-cont-inner-h3" id={props.id ? props.id : ""}>
        <h3>{props.text}</h3>
        {/* <h3>This is a generic h3</h3> */}
    </div>
  )
}

export default H3