import { useNavigate } from "react-router-dom"

import {motion} from 'framer-motion'

import typescript from '../images/typescript.svg'
import javascript from '../images/javascript.svg'
import go from '../images/go.svg'
import java from '../images/java.svg'
import python from '../images/python.svg'
import cpp from '../images/cpp.svg'

import react from '../images/react.svg'
import fiber from '../images/fiber.svg'
import nodejs from '../images/nodejs.svg'
import springboot from '../images/spring.svg'
import raylib from '../images/raylib.png'
import tensorflow from '../images/tensorflow.svg'
import p5 from '../images/p5.png'



import postgres from '../images/postgres.svg'
import mongodb from '../images/mongodb.svg'

import docker from '../images/docker.svg'
import aws from '../images/aws.svg'
import netlify from '../images/netlify.svg'

const Card = ({proj}) => {

    console.log(proj)

    const nav = useNavigate()

    const navToProjectDetails = () => {
        nav(`/projectDetails/${proj.projectName}`)
    }

    const returnImg = (imgString) => {
        switch(imgString){
            case 'typescript':
                return typescript;
                break;
            case 'javascript':
                return javascript;
                break;
            case 'go':
                return go;
                break;
            case 'java':
                return java;
                break;
            case 'python':
                return python;
                break;
            case 'cpp':
                return cpp;
                break;
            
            case 'react':
                return react;
                break;
            case 'fiber':
                return fiber;
                break;
            case 'nodejs':
                return nodejs;
                break;
            case 'springboot':
                return springboot;
                break;
            case 'raylib':
                return raylib
                break;
            case 'tensorflow':
                return tensorflow;
                break;
            case 'p5':
                return p5
                break;

            case 'postgres':
                return postgres;
                break;
            case 'mongodb':
                return mongodb;
                break;

            case 'docker':
                return docker;
                break;
            case 'aws':
                return aws;
                break;
            case 'netlify':
                return netlify;
                break;

            default:
                break;
        }

    }

  return (
    <motion.div layout animate={{opacity:1}} initial={{opacity:0}} exit={{opacity:0}} className="card-outer-cont"  onClick={()=>{navToProjectDetails()}}>
        <div className="card-inner-cont" >
            <div className="card-front">
                <div className="front-cloud">
                    <div className="front-db-logo-cont">
                            {proj.frontCard.db ? (
                                <div className="front-db-logo">
                                    <img src={returnImg(proj.frontCard.db)} alt={proj.frontCard.db} />
                                </div>
                            ) : ("")}
                        {/* <div className="front-db-logo">
                            <img src={postgres} alt="Postgres" />
                        </div> */}
                        {/* <div className="front-db-logo">
                            <img src={mongodb} alt="MongoDB" />
                        </div> */}
                    </div>
                    <div className="front-cloud-logo-cont">
                        {proj.frontCard.cloud.map(individualCloud => (
                            <div className="front-cloud-logo">
                                <img src={returnImg(individualCloud)} alt="individualCloud" />
                            </div>
                        ))}
                        {/* <div className="front-cloud-logo">
                            <img src={docker} alt="Docker" />
                        </div>
                        <div className="front-cloud-logo">
                            <img src={aws} alt="AWS" />
                        </div> */}
                    </div>
                </div>
                <div className="front-title">
                    {/* <h2>Human dd dd dddddddddd dddddd</h2> */}
                    <h2>{proj.frontCard.title}</h2>
                </div>
                <div className="front-tags">
                    {proj.frontCard.tags.map(tag=>(
                        <h2>{tag}</h2>
                    ))}
                    {/* <h2>Front-End</h2>
                    <h2>Game Development</h2> */}
                </div>
                <div className="front-tech-stack">
                    <div className="front-tech-stack-languages">
                            {proj.frontCard.languages.map(language=>(
                                <div className="front-tech-stack-logo">
                                    <img src={returnImg(language)} alt={language} />
                                </div>
                            ))}
                            {/* <img src={cpp} alt="Typescript" /> */}
                        {/* <div className="front-tech-stack-logo">
                            <img src={go} alt="Go" />
                        </div> */}
                    </div>
                    <div className="front-tech-stack-frameworks">
                        {/* <div className="front-tech-stack-logo">
                            <img src={react} alt="React" />
                        </div> */}
                        {proj.frontCard.frameworks.map(framework=>(
                                <div className="front-tech-stack-logo">
                                    <img src={returnImg(framework)} alt={framework} />
                                </div>
                            ))}
                        {/* <div className="front-tech-stack-logo">
                            <img src={raylib} alt="Fiber" />
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="card-back">
                <div className="back-description">
                    <div className="back-description-content">
                        <h2>{proj.backCard.description}</h2>
                        {/* <h2>This</h2> */}
                    </div>
                </div>
                <div className="back-key-features">
                    <div className="back-key-features-cont">
                        <div className="back-key-features-title">
                            <h2>Key Features:</h2>
                        </div>
                        <div className="back-key-features-list">
                            <ul>
                                {proj.backCard.keyFeatures.map(feature=>(
                                    <li>{feature}</li>
                                ))}
                                {/* <li>Feature 1</li>
                                <li>Feature 2</li>
                                <li>Feature 3</li>
                                <li>Feature 4</li>
                                <li>Feature 5</li> */}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="back-deployment">
                    <div className="back-deployment-content">
                        <h2>{proj.backCard.deployment}</h2>
                        {/* <h2>This is some generic description about yet another hierarchical ordered oracle.</h2> */}
                        {/* <h2>Client layer is deployed on Netlify.</h2>
                        <h2>Server & DB layers are deployed on AWS.</h2> */}
                        {/* <h2>Just some random filler text here.</h2> */}
                        {/* <h2>123</h2> */}
                    </div>
                </div>
            </div>
        </div>
    </motion.div>
  )
}

export default Card