
const H2 = ({props}) => {
  return (
    <div className="chapter-cont-inner-h2" id={props.id ? props.id : ""}>
        <h2>{props.text}</h2>
        {/* <h2>This is a generic h2</h2> */}
    </div>
  )
}

export default H2