
const H4 = ({props}) => {
  return (
    <div className="chapter-cont-inner-h4" id={props.id ? props.id : ""}>
        <h4>{props.text}</h4>
        {/* <h4>Just a generic h4 tag.</h4> */}
    </div>
  )
}

export default H4