
const P = ({props}) => {
  return (
    <div className="chapter-cont-inner-p" id={props.id ? props.id : ""}>
      <p>{props.text}</p>
      {/* <p>Just a generic p tag.</p> */}
    </div>
  )
}

export default P