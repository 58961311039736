import logo from '../images/logo.png'
import { useNavigate } from "react-router-dom"




const Header = () => {

  const nav = useNavigate()

  const navToHome = () => {
    nav("/")
  }

  const navToProjects = () => {
    nav("/projects")
  }

  return (
    <div className="header-cont-outer">
        <div className="header-cont-inner">
            {/* <div id="header-logo" onClick={()=>(navToHome())}>
                <img src={logo} alt="logo" />
            </div> */}
            <h2 id='header-projects' onClick={()=>(navToProjects())}>Projects</h2>
        </div>
    </div>
  )
}

export default Header